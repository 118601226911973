import React, { useEffect, useState } from "react";
import PreloaderIcon from "../Shared/PreloaderIcon";
import { baseUrl, noLogo } from "src/config/host";
import { imageFileNameExtractor } from "src/helpers/imageFileNameExtractor";

const ImageImport = ({ item, onDragStart = () => {} }) => {
  const [loading, setLoading] = useState(true);
  const [loadedImage, setLoadedImage] = useState("");
  const [loadedText, setLoadedText] = useState("");

  useEffect(() => {
    if (item?._id) {
      assignImage();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?._id]);

  const assignImage = () => {
    let { name, image, _id } = item;
    setLoadedText(name);
    setLoadedImage(
      <img
        className="h-16 w-20 p-3 cursor-move hover:border-gray-300 hover:shadow border border-gray-200 flex items-center justify-center rounded-md shadow-sm"
        alt="chart"
        id={_id}
        draggable={true}
        unselectable="on"
        onDragStart={onDragStart}
        src={`${
          process.env.REACT_APP_API_BASE_URL
        }/api/image/${imageFileNameExtractor(image)}`}
        onError={(e) => (e.target.src = `${noLogo}`)}
      />
    );
  };

  return (
    <>
      {loading ? (
        <PreloaderIcon />
      ) : (
        <div>
          {loadedImage}
          <p className="text-xs mt-[4px] text-gray-600 w-20 h-7 flex text-wrap text-center justify-center">
            {loadedText}
          </p>
        </div>
      )}
    </>
  );
};

export default ImageImport;
