import { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import { toolbarOptions } from "src/config/toolbar";

const TextEditorImport = ({ value = "", setValue = () => {} }) => {
  const editorRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isHoveringToolbar, setIsHoveringToolbar] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    if (
      !event.relatedTarget ||
      !editorRef.current.editor.container.contains(event.relatedTarget)
    ) {
      setIsFocused(false);
    }
  };

  const handleMouseEnterToolbar = () => {
    setIsHoveringToolbar(true);
  };

  const handleMouseLeaveToolbar = () => {
    setIsHoveringToolbar(false);
  };

  // Show/hide toolbar based on focus state
  useEffect(() => {
    const quill = editorRef.current.getEditor();
    const toolbar = quill.getModule("toolbar").container;
    if (isFocused || isHoveringToolbar) {
      toolbar.style.display = "block";
    } else {
      toolbar.style.display = "none";
    }

    // Add event listeners for toolbar hover
    toolbar.addEventListener("mouseenter", handleMouseEnterToolbar);
    toolbar.addEventListener("mouseleave", handleMouseLeaveToolbar);

    return () => {
      toolbar.removeEventListener("mouseenter", handleMouseEnterToolbar);
      toolbar.removeEventListener("mouseleave", handleMouseLeaveToolbar);
    };
  }, [isFocused, isHoveringToolbar]);

  return (
    <div className="flex w-full h-[calc(100%-36px)]">
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ width: "100%" }}
        className="custom-editor"
        ref={editorRef}
        modules={{
          toolbar: toolbarOptions,
        }}
      />
    </div>
  );
};

export default TextEditorImport;
