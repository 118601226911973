import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Button";
import Input from "src/components/Shared/Input";
import Modal from "src/components/Shared/Modal";
import { getCookie } from "src/helpers/cookieHelper";

const DashboardShareModal = ({
  isOpen,
  permission,
  dashboardId,
  onCancel = () => {},
  onSuccess = () => {},
}) => {
  const options = [
    {
      key: "Anyone with the link",
      value: `viewer`,
      label: `Anyone on the internet with the link can view`,
    },
    {
      key: "Restricted",
      value: `editor`,
      label: `Anyone on the internet with the link can edit`,
    },
  ];
  const { dashboard_id } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [linkCopySuccess, setLinkCopySuccess] = useState(false);
  const [secretCopySuccess, setSecretCopySuccess] = useState(false);

  const saveDashboard = async () => {
    try {
      setDisabled(true);
      const { data } = await apiRequest(
        "POST",
        `/dashboards`,
        {
          body: {
            dashboard_id: dashboard_id || getCookie("dashboard_id"),
            permission: editPermission,
          },
        },
        {
          showToastMessage: true,
          isAuthorized: dashboard_id ? true : false,
        }
      );
      onSuccess(data?.data?.permission);
      setDisabled(false);
    } catch (error) {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (permission) {
      setEditPermission(permission);
    }
  }, [permission]);

  return (
    <Modal
      title="Dashboard"
      secondaryTitle={`Share`}
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={saveDashboard}
      defaultOptions={{
        onCancelButtonText: "Close",
        onSuccessLoaderVisible: disabled,
        onSuccessLoaderStart: disabled,
        onSuccessButtonVisible: false,
      }}
    >
      <div className="relative w-full space-y-10">
        {/* <div className="relative space-y-1">
          <div className="text-sm font-semibold text-gray-800">General Access</div>
          <div className="flex items-center rounded-md border border-gray-300 bg-gray-100 py-4 px-4 gap-3">
            <div className="flex-shrink-0">
              <GlobeAmericasIcon className="w-8 h-8 text-gray-500"/>
            </div>
            <div className="w-full flex-shrink">
              <div className="relative">
                <SelectMenu
                  startIndex={options.findIndex((op) => op?.value === editPermission?.type) || 0}
                  options={options}
                  setOption={(e) => {
                    setEditPermission((prevData) => ({...prevData, type: e.value}))
                  }}
                  classes="!border-0 !h-auto !p-0 !bg-transparent !w-auto !pr-10 !shadow-none !font-semibold"
                  size={"sm"}
                />
              </div>
              <div className="text-xs text-gray-600">{options.find((op) => op?.value === editPermission?.type)?.label}</div>
            </div>
            <div className="flex-shrink-0">
              <div className="h-8 rounded-full px-3 flex items-center justify-center text-xs font-medium bg-blue-600 text-white capitalize">{options.find((op) => op?.value === editPermission?.type)?.value}</div>
            </div>
          </div>
        </div> */}
        <div className="space-y-3">
          <div className="relative">
            <div className="flex items-end relative">
              <Input
                disabled={false}
                value={`${window.location.origin}/dashboard/${dashboardId}`}
                label={"Link to Dashboard"}
                inputClassNames="w-full flex-shrink !h-10 !rounded-r-none !border-blue-300"
              />
              <Button
                className="flex-shrink-0 !py-0 !h-10 !px-3 !items-center !gap-1 !rounded-md !rounded-l-none !bg-white !text-gray-500 !border-l-0 !shadow-none !ring-0 focus:!border-blue-300 focus:!ring-0"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/dashboard/${dashboardId}`
                  );
                  setLinkCopySuccess(true);
                  setTimeout(() => {
                    setLinkCopySuccess(false);
                  }, 2000);
                }}
              >
                <DocumentDuplicateIcon className="w-4 h-4" />
                <span className="text-xs">
                  {linkCopySuccess ? "Copied!" : "Copy"}
                </span>
              </Button>
              {linkCopySuccess && (
                <div className="w-4 h-4 rounded-full bg-green-500 overflow-hidden absolute -right-1 top-5 flex items-center justify-center">
                  <CheckIcon className="w-3 h-3 text-white font-bold" />
                </div>
              )}
            </div>
          </div>
          <div className="relative pb-10">
            <div className="flex items-end">
              <Input
                disabled={false}
                label={"Login Secret"}
                value={editPermission?.secret}
                inputClassNames="w-full flex-shrink !h-10 !rounded-r-none !border-blue-300"
              />
              <Button
                className="flex-shrink-0 !py-0 !h-10 !px-3 !items-center !gap-1 !rounded-md !rounded-l-none !bg-white !text-gray-500 !border-l-0 !shadow-none focus:!border-blue-300 focus:!ring-0"
                onClick={() => {
                  navigator.clipboard.writeText(editPermission?.secret);
                  setSecretCopySuccess(true);
                  setTimeout(() => {
                    setSecretCopySuccess(false);
                  }, 2000);
                }}
              >
                <DocumentDuplicateIcon className="w-4 h-4" />
                <span className="text-xs">
                  {secretCopySuccess ? "Copied!" : "Copy"}
                </span>
              </Button>
              {secretCopySuccess && (
                <div className="w-4 h-4 rounded-full bg-green-500 overflow-hidden absolute -right-1 top-5 flex items-center justify-center">
                  <CheckIcon className="w-3 h-3 text-white font-bold" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DashboardShareModal;
