import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Button from "src/components/Shared/Button";
import Input from "src/components/Shared/Input";
import Modal from "src/components/Shared/Modal";
import TextArea from "src/components/Shared/TextArea";

const DashboardManageModal = ({
  isOpen,
  item,
  layout,
  name,
  onCancel = () => {},
  onSuccess = () => {},
}) => {
  const [cardItem, setCardItem] = useState(null);
  const [comment, setComment] = useState("");

  const saveDashboardCard = async () => {
    onSuccess([
      ...layout.map((lyt) => {
        if (lyt?.i === cardItem?.i) {
          return {
            ...lyt,
            ...cardItem,
          };
        } else {
          return lyt;
        }
      }),
    ]);
  };

  useEffect(() => {
    setComment("");
    setCardItem(item);
  }, [item]);

  return (
    <Modal
      title="Card"
      secondaryTitle={`Manage`}
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={saveDashboardCard}
    >
      <div className="relative w-full space-y-4">
        <div className="relative w-full mb-4">
          <Input
            label={`Title`}
            value={cardItem?.title}
            onChange={(e) =>
              setCardItem((prevLayout) => ({
                ...prevLayout,
                title: e.target.value,
              }))
            }
          />
        </div>
        <div className="relative w-full mb-4">
          <TextArea
            name="description"
            label="Description"
            value={cardItem?.description}
            onChange={(e) =>
              setCardItem((prevLayout) => ({
                ...prevLayout,
                description: e.target.value,
              }))
            }
          />
        </div>
        <div className="relative border border-gray-300 rounded px-2 py-2">
          <div className="relative w-full mb-4 flex justify-between items-center gap-4">
            <Input
              //label={`Comment`}
              value={comment}
              placeholder={`Comment`}
              inputClassNames="!border-gray-300 !rounded-md W-full flex-shrink"
              onChange={(e) => setComment(e.target.value)}
            />
            <Button
              disabled={!comment}
              className="flex-shrink-0"
              onClick={() => {
                if (comment) {
                  setCardItem((prevLayout) => ({
                    ...prevLayout,
                    comments: [
                      ...(prevLayout?.comments || []),
                      { title: comment, is_completed: false },
                    ],
                  }));
                  setComment("");
                }
              }}
            >
              Add
            </Button>
          </div>
          {cardItem?.comments?.length > 0 && (
            <>
              <hr />
              <div className="relative space-y-3 py-4">
                {cardItem.comments.map((comment, index) => {
                  return (
                    <div className="relative flex gap-2" key={index}>
                      <input
                        type="checkbox"
                        name=""
                        className="!rounded peer cursor-pointer !border-gray-400 mt-0.5"
                        checked={comment?.is_completed}
                        onChange={(e) => {
                          setCardItem((prevLayout) => ({
                            ...prevLayout,
                            comments: [
                              ...prevLayout?.comments.map((cmt, i) => {
                                if (i === index) {
                                  return {
                                    ...cmt,
                                    is_completed: e.target.checked,
                                  };
                                } else {
                                  return cmt;
                                }
                              }),
                            ],
                          }));
                        }}
                      />
                      <label className="text-sm leading-tight text-gray-800 peer-checked:line-through peer-checked:text-gray-400">
                        {comment?.title}
                      </label>
                      <Button
                        className="flex-shrink-0 !p-0 !h-auto !bg-transparent ml-auto !border-0 !text-gray-400"
                        onClick={() => {
                          setCardItem((prevLayout) => ({
                            ...prevLayout,
                            comments: [
                              ...prevLayout?.comments.filter(
                                (_, i) => i !== index
                              ),
                            ],
                          }));
                        }}
                      >
                        <XMarkIcon className="w-5 h-5" />
                      </Button>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DashboardManageModal;
