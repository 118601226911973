import Cookies from 'js-cookie';

const setCookie = (name, value, expires = 7) => {
  Cookies.set(name, value, {expires, path: window.location.pathname});
}

const getCookie = (name) => {
  return Cookies.get(name)
}

const removeCookie = (name) => {
  Cookies.remove(name, {path: window.location.pathname})
}

export {
  setCookie,
  getCookie,
  removeCookie
}