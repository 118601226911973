import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchData } from "src/async/fetch";
import Input from "src/components/Shared/Input";
import Modal from "src/components/Shared/Modal";
import { apiUrl } from "src/config/host";
import { getCookie, setCookie } from "src/helpers/cookieHelper";

const DashboardPermissionModal = ({ isOpen }) => {
  const { dashboard_id } = useParams()
  const [disabled, setDisabled] = useState(false);
  const [secret, setSecret] = useState(null);

  const generateToken = async () => {
    try {
      setDisabled(true);
      const res = await fetchData("POST", `${apiUrl}/token/generate`, {
        dashboard_id: dashboard_id || getCookie("dashboard_id"),
        secret
      });
      const data = await res.json();
      if (data.status === 200) {
        setCookie('access_token', data?.accessToken)
        window.location.reload();
      } else {
        toast.error(data.message);
      }
      setDisabled(false)
    } catch (error) {
      toast.error(error.message);
      setDisabled(false)
    }
  };

  return (
    <Modal
      title="Dashboard"
      secondaryTitle={`Permission`}
      isOpen={isOpen}
      onSuccess={generateToken}
      defaultOptions={{
        onCancelButtonVisible: false,
        onSuccessButtonText: "Submit",
        onSuccessLoaderVisible: disabled,
        onSuccessLoaderStart: disabled,
      }}>
      <div className="relative w-full space-y-4">
        <div className="relative w-full mb-4">
          <Input
            label={`Secret`}
            placeholder="Secret"
            value={secret}
            onChange={(e) => setSecret(e.target.value)} />
        </div>
      </div>
    </Modal>
  );
};

export default DashboardPermissionModal
