const PermissionDenied = () => {
  return (
    <div className="w-full h-full max-h-[calc(100%-4rem)] flex justify-center items-center">
        <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col items-center">
          <h2 className="mt-6 text-center text-3xl sm:text-3xl font-extrabold text-gray-900">Sorry, you don't have any access to content yet.</h2>
          <div className="mt-1 text-center text-regular text-gray-600">Contact us and request access to your pages and analytics.</div>
        </div>
      </div>
  );
};

export default PermissionDenied;
