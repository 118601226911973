import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "src/ScrollToTop";
import AppRoutes from "src/routes/AppRoutes";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import "react-quill/dist/quill.snow.css";
import "./index.css";

export default function App() {
  return (
    <BrowserRouter>
      <div className="h-screen bg-backgroundColor">
        <ScrollToTop />
        <ToastContainer
          position="top-right"
          autoClose={900}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AppRoutes />
      </div>
    </BrowserRouter>
  );
}
