import { classNames } from "src/helpers/classNames";

const Input = ({
  autoComplete = "off",
  label,
  name,
  placeholder = "Title",
  disabled = false,
  value = "",
  error = false,
  type = "text",
  inputClassNames = "",
  labelClassNames = "",
  onChange = () => {},
  onFocus = () => {},
  onKeyDown = () => {},
  onBlur = () => {},
}) => {
  const labelClasses = classNames(
    labelClassNames,
    "flex justify-between text-sm font-medium",
    error ? "text-red-600" : "",
    disabled ? "text-gray-400 cursor-not-allowed" : "text-gray-800"
  );
  const inputClasses = classNames(
    "h-10 block w-full flex items-center sm:text-sm border-gray-200 rounded-md shadow-sm focus:ring-0 focus:border-highlightColor",
    error ? "border-red-300" : "",
    disabled ? "text-gray-300 cursor-not-allowed bg-gray-50/50" : "",
    inputClassNames ? inputClassNames : "leading-normal" // Adjusted line height
  );
  return (
    <div className="space-y-1 w-full">
      {label && (
        <label
          htmlFor={name}
          className={labelClasses}>
          {label} <div className="pl-2">{error ? "*Required" : ""}</div>
        </label>
      )}
      <input
        disabled={disabled}
        value={value}
        type={type}
        name={name}
        autoComplete={autoComplete}
        onFocus={onFocus}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        className={inputClasses}
        placeholder={disabled ? "Title" : placeholder}
      />
    </div>
  );
};

export default Input;
