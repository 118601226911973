import { Route, Routes } from "react-router-dom";

import Dashboard from "src/pages/Dashboard";
import ErrorPage from "src/pages/404";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Dashboard />}>
      </Route>
      <Route
        path="/dashboard/:dashboard_id"
        element={<Dashboard />}>
      </Route>
      <Route
        path="*"
        element={<ErrorPage />}
      />
    </Routes>
  );
};

export default AppRoutes;
