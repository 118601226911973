import { apiUrl } from "src/config/host";

const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
  removeItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.removeItem(key);
    });
  },
};

export const refreshTokenVerify = async () => {
  const refreshTokenRes = await fetch(`${apiUrl}/refresh-token/verify`, {
    method: 'POST',
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("refresh_token"),
    }),
    body: JSON.stringify({}),
  });

  let refreshTokenData = {}

  if (refreshTokenRes.status === 200) {
    refreshTokenData = await refreshTokenRes.json()
    localStorage.setItem('access_token', refreshTokenData.access_token)
    localStorage.setItem('refresh_token', refreshTokenData.refresh_token)
  } else {
    await asyncLocalStorage.removeItem("userId");
    await asyncLocalStorage.removeItem("access_token");
    await asyncLocalStorage.removeItem("refresh_token");
  }

  return { refreshTokenRes, refreshTokenData }
}