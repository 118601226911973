import { useEffect, useState } from "react";
import PreloaderIcon from "../Shared/PreloaderIcon";
import ReactHtmlParser from "react-html-parser";

const IframeImport = ({ optionList, title }) => {
  const [loading, setLoading] = useState(true);
  const [srcDoc, setSrcDoc] = useState("");

  useEffect(() => {
    if (title && optionList.length > 0) {
      assignIframe();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, optionList]);

  const assignIframe = () => {
    let data = optionList.find((op) => op?.type === title);
    setSrcDoc(`<iframe src=${data?.path} width="100%" height="100%" marginheight="0" marginwidth="0" frameborder="0"></iframe>`);
    setLoading(false);
  };

  return <>{loading ? <PreloaderIcon /> : ReactHtmlParser(srcDoc)}</>;
};

export default IframeImport;
